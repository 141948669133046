<template>
	<div>
		<div class="breadcrumb">
			<el-breadcrumb separator-class="el-icon-arrow-right">
				<el-breadcrumb-item :to="{ path: '/' }">首页</el-breadcrumb-item>
				<el-breadcrumb-item :to="{ path: item.path }" v-for="(item,index) in breadcrumb" :key="index">{{item.name}}</el-breadcrumb-item>
			</el-breadcrumb>
		</div>
		<el-row>
			<el-col :span="24">
				<div class="panel">
					<div class="panel-body pad16">

						
						<div class="fcj_ll" style="font-size:12px;margin-bottom:5px;">
							<el-input placeholder="客户名称" style="width:150px;margin-right:10px;" size="small"  @keyup.enter.native="getdata"
								clearable v-model="search.company"
								v-show="type=='history'||type=='rent'||type=='ele'||type=='invoice'" />
							<el-select v-model="search.typeid" slot="prepend" size="small" clearable
								placeholder="费用类型" multiple collapse-tags @change="getdata" style="margin-right:10px;width:170px;"
								v-show="type=='history'||type=='rent'||type=='invoice'||contractid!=''">
								<el-option v-for="item in paytype" :key="item.fid" :label="item.class_name" v-show="item.fid!=91||type!='rent'"
									:value="item.fid">{{item.class_name}}</el-option>
							</el-select>
							<!-- <el-select v-model="search.state" slot="prepend" size="small" clearable
								placeholder="账单状态" @change="getdata" style="margin-right:10px;width:100px;" v-if="type!='rent'">
								<el-option v-for="(item,index) in billstate" :key="index" :label="item"
									:value="index">{{item}}
								</el-option>
							</el-select> -->
							<!-- <el-select v-model="search.bankid" slot="prepend" size="small" clearable placeholder="收款账号"
								@change="getdata" style="margin-right:10px;">
								<el-option v-for="(item,index) in banks" :key="index" :label="item.des"
									:value="index">{{item.des}}
								</el-option>
							</el-select> 
							-->
							<el-select v-model="search.payed" multiple collapse-tags size="small" clearable placeholder="付款状态"
								@change="getdata" style="margin-right:10px;width:100px;" v-if="type=='invoice'||contractid!=''">
								<el-option v-for="(item,index) in paystate" :key="index" :label="item.name"
									:value="index">{{item.name}}
								</el-option>
							</el-select>
							<el-select v-model="search.io" size="small" clearable placeholder="出入"
								@change="getdata" style="margin-right:10px;width:80px;">
								<el-option label="入账"
									value="0">入账
								</el-option>
								<el-option label="出账"
									value="1">出账
								</el-option>
							</el-select>
							<el-select v-model="search.bankname" collapse-tags size="small" clearable placeholder="收款账号"
								@change="getdata" style="margin-right:10px;width:100px;" >
								<el-option v-for="(item,index) in banksmap" :key="index" :label="item.n"
									:value="index">{{item.n}}
								</el-option>
							</el-select>
							<el-select v-model="search.readstate" size="small" clearable placeholder="读取状态"
								@change="getdata" style="margin-right:10px;width:100px;" v-if="type=='invoice'||contractid!=''">
								<el-option label="未发送" value="0">未发送</el-option>
								<el-option label="已发未读" value="1">已发未读</el-option>
								<el-option label="已发已读" value="2">已发已读</el-option>
							</el-select>
							<el-input placeholder="导入批号" style="width:120px;margin-right:10px;" size="small"
								clearable v-model="search.filenumber" v-if="type=='history'||type=='ele'" />

							
							<el-select v-model="search.invoicestate" size="small" clearable placeholder="发票状态"
								@change="getdata" style="margin-right:10px;width:110px;" v-if="type=='invoice'">
								<el-option label="已登记" value="1">已登记</el-option>
								<el-option label="未登记" value="0">未登记</el-option>
							</el-select>
							<el-date-picker type="month" placeholder="选择日期" size="small" value-format="yyyy-MM" @change="getdata" v-model="search.invoice_in_time" style="width: 140px;"></el-date-picker>
							<el-select v-model="search.dikou" size="small" clearable placeholder="是否抵扣"
								@change="getdata" style="margin-right:10px;width:110px;" v-if="type=='invoice'">
								<el-option label="有" value="1">有</el-option>
								<el-option label="无" value="0">无</el-option>
							</el-select>
							<!-- <el-date-picker type="date" placeholder="账单开始日期" size="small" v-model="search.billtimestart"
								style="width: 160px;margin-right:10px;" @change="getdata" v-if="type=='rent'">
							</el-date-picker> -->
							<el-date-picker type="month" placeholder="账单周期" size="small" v-model="search.month"
								style="width: 130px;margin-right:10px;" @change="getdata" v-if="type=='ele'">
							</el-date-picker>

							
						</div>
						<div class="fcj_ll" style="font-size:12px;">
							<div class="fcj_ll">
								<div style="padding:0 8px;">账单金额</div>
								<div><el-input placeholder="最小" clearable style="width:90px;margin-right:10px;" size="small" v-model="search.minmoney"></el-input></div>
								<div><el-input placeholder="最大" clearable style="width:90px;margin-right:10px;" size="small" v-model="search.maxmoney"></el-input></div>
							</div>
							<div class="fcj_ll">
								<div style="padding:0 8px;">账单周期</div>
								<el-date-picker v-model="search.billtime" type="daterange"
									style="margin-right:10px;" range-separator="至" start-placeholder="开始日期"
									end-placeholder="结束日期" size="small">
								</el-date-picker>
							</div>
							<!--按区查订单 <div v-if="showqu">
								<el-select v-model="search.office_area" size="small" clearable placeholder="区"
								@change="getdata" style="margin-right:10px;width:100px;" >
								<el-option primary v-for="(item,index) in officearea" :label="item" :value="item" :key="index">
									{{item}}
								</el-option>
									
								</el-select>
							</div> -->
							
							<div>
								<el-select v-model="search.payerid" size="small" clearable placeholder="收费人"
								@change="getdata" style="margin-right:10px;width:100px;" >
									<el-option primary v-for="(item,index) in staff" :label="item.tname" :value="item.uid" :key="index">
										{{item.tname}}
									</el-option>
									
								</el-select>
							</div>
							<div>
								<el-select v-model="search.isbad" size="small" clearable placeholder="是否坏账"
								@change="getdata" style="margin-right:10px;width:100px;" >
									<el-option label="坏账" value="1" >
									</el-option>
									<el-option label="非坏账" value="0" >
									</el-option>
								</el-select>
							</div>
							<el-button type="primary" size="small" icon="el-icon-search" @click="getdata()">查询
							</el-button>
							<el-button size="small" type="success" plain v-if="type=='invoice'" @click="downloadbill()" style="margin-left:10px;">下载</el-button>

							<el-button icon="el-icon-download" size="small" v-if="type=='rent'" type="success" @click="outbill">导出</el-button>

						</div>
						<div>
							<div class="table-contral">
								<div class="fcj_lr">
									<div>
										<el-button size="small" type="primary" plain @click="downexcel()"
											v-if="type=='ele'">表格下载</el-button>
										<el-button size="small" type="primary" plain @click="showeledatain()"
											v-if="type=='ele'">数据导入</el-button>
										<el-button size="small" type="primary" plain @click="deleledata('s')"
											v-if="type=='ele'">批量删除</el-button>
													
										<el-button size="small" type="primary" plain @click="showsend()"
											>账单确认</el-button>
										<el-button size="small" type="primary" plain @click="updatebank(1)"
											v-if="type=='rent'||type=='ele'">更换收款账号</el-button>
										
										<el-button size="small" type="primary" plain  @click="showbat">批量收款登记</el-button>
										<el-button size="small" type="primary" plain v-if="type=='invoice'" @click="showbatinvoice">批量发票登记</el-button>
										
										<el-popover placement="top" width="160" v-model="pivisible"  v-if="type=='invoice'||type=='history'" 
											style="margin-left:10px;">
											<p>确认要发送吗？</p>
											<div style="text-align: right; margin: 0">
												<el-button size="mini" type="text" @click="pivisible = false">取消
												</el-button>
												<el-button type="primary" size="mini" @click="send(2)">确定
												</el-button>
											</div>

											<el-button slot="reference" size="small" type="primary"  plain>批量发送不通知</el-button>
										</el-popover>
										<el-popover placement="top" width="160" v-model="pivisiblemsg"  v-if="type=='invoice'||type=='history'"
											style="margin-left:10px;">
											<p>确认要发送通知吗？</p>
											<div style="text-align: right; margin: 0">
												<el-button size="mini" type="text" @click="pivisiblemsg = false">取消
												</el-button>
												<el-button type="primary" size="mini" @click="send(3)">确定
												</el-button>
											</div>
										
											<el-button slot="reference" size="small" type="primary"  plain>批量发送并通知</el-button>
										</el-popover>
										

										<el-button size="small" type="primary" plain v-if="contractid!==''&&contract.state==1" @click="goini()">账单初始化</el-button>
										<el-button size="small" type="primary" plain v-if="contractid!==''" @click="showbillform()">新建账单</el-button>
										<el-button size="small" type="primary" plain v-if="contractid!==''&&contract.state<2" @click="zuofei()">批量作废</el-button>
										<el-button size="small" type="primary" plain v-if="contractid!==''&&contract.state<2" @click="allzuofei()">全部作废</el-button>
										<el-button size="small" type="primary" plain v-if="type=='invoice'" @click="backstate()" style="margin-left:10px;">批量回退</el-button>
											
									</div>
									<div>
										<div style="font-size:12px;color:#999;"><el-tag size="small" v-if="semoney!==''" type="success">已选账单总额： {{semoney}}</el-tag> <el-tag size="small">总额： {{allamount}}</el-tag> <el-tag size="small">实收：{{paymoney}}</el-tag> <el-tag size="small">未收：{{notpay}}</el-tag> <el-tag size="small">应退：{{allreturnm}}</el-tag> <el-tag size="small">实退：{{returnm}}</el-tag></div>
									</div>
								</div>
								
							</div>
							<el-table :data="res" @selection-change="handleSelectionChange"
								header-cell-class-name="table-header" cell-class-name="fcj" @sort-change="sortchange" :cell-style="cellstyle" stripe>

								<el-table-column type="selection" width="55" fixed>
								</el-table-column>
								
								<el-table-column prop="company" label="客户名称" width="250">
									<template slot-scope="scope">
										<div @click="chcontrctid(scope.row.contractid)" style="color:#4d7cfe;cursor:pointer;"><el-tag style="margin-right:5px;" size="mini" v-if="scope.row.isbad==1" effect="dark" type="warning">坏</el-tag><el-tag style="margin-right:5px;" size="mini" v-if="scope.row.offset!=0" effect="dark" type="danger">抵</el-tag>{{scope.row.company}}</div>
									</template>
								</el-table-column>
								<el-table-column prop="office" label="租赁物业" width="200"  :sortable="true">
									<template slot-scope="scope">
										<div :title="scope.row.office+scope.row.address"><el-tag v-if="scope.row.ptypename" size="small">{{ scope.row.ptypename.class_name }}</el-tag>{{scope.row.office+scope.row.address}}
										</div>
									</template>
								</el-table-column>
								<el-table-column prop="company" label="费用类型" width="100">
									<template slot-scope="scope">
										{{paytypemap[scope.row.typeid]?paytypemap[scope.row.typeid].class_name:''}}
									</template>
								</el-table-column>
								<el-table-column label="账单周期" width="250"  prop="ra1" :sortable="true">
								</el-table-column>
								<el-table-column prop="io" label="出入" width="60">
									<template slot-scope="scope">
										{{scope.row.io==1?'出账':''}}
									</template>
								</el-table-column>
								<el-table-column prop="amountmoney" label="账单金额" align="right" width="110">
								</el-table-column>
								<el-table-column prop="paymoney" label="实收/付金额" align="right"  width="110">
								</el-table-column>
								<el-table-column prop="offset" label="抵扣" align="right"  width="110">
								</el-table-column>
								<el-table-column prop="notpay" label="未收/付金额" align="right"  width="110">
									<template slot-scope="scope">
										{{scope.row.notpay.accSub(scope.row.offset)}}
									</template>
								</el-table-column>
								<el-table-column label="账单头寸" align="right" width="110">
									<template slot-scope="scope">
										{{scope.row.cuntou}}
									</template>
								</el-table-column>
								<el-table-column prop="invoice" label="发票编号" align="right" width="180">
								</el-table-column>
								<el-table-column prop="invoice_in_time" label="发票日期"  width="100">
								</el-table-column>
								<el-table-column prop="number" label="合同编号" width="180">
								</el-table-column>
								<el-table-column prop="note" label="备注" width="120">
									<template slot-scope="scope">
										<div :title="scope.row.note">{{scope.row.note.substr(0,10)}}</div>
									</template>
								</el-table-column>
								
								<el-table-column label="合同总头寸" align="right" width="110">
									<template slot-scope="scope">
										{{scope.row.tj_cash}}
									</template>
								</el-table-column>

								<el-table-column prop="state" label="状态"  fixed="right">
									<template slot-scope="scope">
										<el-tag :style="scope.row.state=='2'?'color:#fff;background-color:#95cc6f':''"
											:type="scope.row.state=='0'?'info':(scope.row.payed==2?'success':(scope.row.state=='-1'?'info':(scope.row.payed==0?'danger':'warning')))">
											{{scope.row.state==1?(scope.row.payed==0?'未支付':scope.row.payed==1?'部份支付':'已支付'):(scope.row.staten=='-1'?'已删除':billstate[scope.row.state])}}
										</el-tag>

									</template>
								</el-table-column>
								<el-table-column prop="filenumber" label="导入批号" width="130" key="filenumber">
								</el-table-column>
								<el-table-column label="操作" :width="'120'" fixed="right">
									<template slot-scope="scope">

										<el-dropdown @command="menuClick" trigger="click">
											<el-button size="mini">
												更多操作<i class="el-icon-arrow-down el-icon--right"></i>
											</el-button>
											<el-dropdown-menu slot="dropdown">
												<el-dropdown-item :command="[1,scope.row]">账单详情</el-dropdown-item>
												<el-dropdown-item :command="[2,scope.row]">编辑账单</el-dropdown-item>
												<el-dropdown-item :command="[3,scope.row,scope.$index]" :disabled="scope.row.state==0">{{scope.row.io==1?'付款登记':'收款登记'}}</el-dropdown-item>
												<el-dropdown-item :command="[4,scope.row,scope.$index]" :disabled="scope.row.state==0">发票登记</el-dropdown-item>
												<el-dropdown-item :command="[5,scope.row,scope.$index]" :disabled="contractid==''">作废</el-dropdown-item>
												<el-dropdown-item :command="[6,scope.row]" :disabled="contractid==''||scope.row.io==1||scope.row.state==0||scope.row.payed==0">负项</el-dropdown-item>
												<el-dropdown-item :command="[7,scope.row]">坏账</el-dropdown-item>
												
											</el-dropdown-menu>
										</el-dropdown>


										<!-- <el-button size="mini" @click="gobillinfo(scope.row)" v-if="type=='invoice'||type=='rent'||type=='ele'||type=='history'||contractid!==''" :disabled="contractid!==''&&contract.state>1">账单详情</el-button>
										<el-button size="mini" @click="showbillform(scope.row)" v-if="type=='rent'||type=='ele'||type=='history'||contractid!==''" :disabled="contractid!==''&&contract.state>1">编辑账单</el-button>
										<el-button size="mini" @click="showaddlog(scope.$index,scope.row)" v-if="type=='invoice'">收款登记</el-button>
										<el-button size="mini" @click="showaddinvoice(scope.$index,scope.row)" v-if="type=='invoice'||type=='history'">发票登记</el-button>
										<el-button size="mini" @click="backstate(scope.$index,scope.row)" v-if="type=='invoice'">回退</el-button>
										
										<el-button size="mini" @click="zuofei(scope.$index,scope.row)" v-if="contractid!==''" :disabled="contractid!==''&&contract.state>1">作废</el-button>
										<el-button size="mini" @click="showsubbillform(scope.$index,scope.row)" v-if="contractid!==''&&scope.row.io==0" :disabled="contractid!==''&&contract.state>1">负项</el-button>
										
										<el-popconfirm title="确认要删除吗？" @confirm="deleledata(scope.$index, scope.row)" v-if="type=='ele'" style="margin-left:10px;">
											<el-button slot="reference" size="small">删除</el-button>
										</el-popconfirm> -->
										
									</template>
								</el-table-column>
							</el-table>

							<div style="padding:15px;text-align: right;" class="fcj_lr">
								<el-button v-if="backtag" type="primary" @click="isrerent=true">退租</el-button>
								<el-pagination background layout="total,prev, pager, next" :current-page.sync="pgno"
									:page-size="pgsize" @current-change="page" :total="pgcount">
								</el-pagination>
							</div>
						</div>
					</div>
				</div>
			</el-col>
		</el-row>

		
		<!-- 批量更换收款账号 -->
		<el-dialog :visible.sync="chbankshow" width="500px">
			<div style="margin-bottom:20px;">
				<el-select v-model="chbankid" placeholder="请选择收款账号" style="width:70%;margin-right: 20px;">
					<el-option primary v-for="(item,index) in banks" :label="item.des" :value="index" :key="index">
						{{item.des}}
					</el-option>
				</el-select>
		
				<el-button @click="updatebank()">确认</el-button>
			</div>
		</el-dialog>
		<!-- 批量收款登记 -->
		<el-dialog :visible.sync="isshowbat" width="500px">
			<div style="text-align: center;">确定将选中账单登记为收款完成，且实收总金额等于账单金额吗？</div>
			<div style="margin-top:20px;">
				<div class="fcj_lc" style="margin:40px 0;">
					<div>本次付款日期：</div>
					<div>
						<el-date-picker placeholder="选择日期" v-model="paytime" style="width: 200px;">
						</el-date-picker>
					</div>
				</div>
				
				<div class="fcj_lc">
					<div><el-button type="primary" @click="submitbatpayfrom()">确认</el-button></div>
					<div style="margin-left: 20px;"><el-button @click="isshowbat=false">取消</el-button></div>
				</div>
				
			</div>
		</el-dialog>
		<!-- 收款登记 -->
		<el-dialog :visible.sync="isshowaddlog" width="1000px" :title="acbill.io==1?'出账':'入账'">
			<el-row>
				<el-col :span="24">
					
				</el-col>
				</el-row>
			<div style="padding:10px;background-color: #f5f5f5;border-radius: 8px;margin:10px 0 20px 0;">
				<div class="fcj_lr">
					<div style="flex:1">
						<el-row>
							<el-col :span="12">
								<div>企业名称：{{acbill.company}}</div>
							</el-col>
							<el-col :span="12">
								<div v-if="acbill">{{(acbill.io==1?'已付金额：':'已收金额：')+Math.abs(acbill.paymoney)}}</div>
							</el-col>
						</el-row>
						<el-row>
							<el-col :span="12">
								<div v-if="acbill">{{(acbill.io==1?'应付金额：':'应收金额：')+Math.abs(acbill.amountmoney)}}</div>
							</el-col>
						
							<el-col :span="12">
								<div v-if="isshowaddlog">{{(acbill.io==1?'未付金额：':'未收金额：')+(acbill.io==1?-parseFloat(acbill.amountmoney.accSub(acbill.paymoney)).toFixed(2):parseFloat(acbill.amountmoney.accSub(acbill.paymoney).accSub(acbill.offset)).toFixed(2))}}</div>
							</el-col>
						
						</el-row>
						<el-row v-if="acbill.io==0">
							<el-col :span="12">
								<div v-if="acbill">抵扣：{{acbill.offset}}</div>
							</el-col>
						
							<el-col :span="12">
								</el-col>
						
						</el-row>
					</div>
					<div>
						
						<el-button type="primary" v-if="acbill.payed<2" @click="isshowstar=true">{{acbill.io==0?'完成收款':'完成付款'}}</el-button>
						
						<div v-else style="margin-right:20px;" >{{acbill.io==0?'已完成收款':'已完成付款'}}</div>
					</div>
				</div>
				
			</div>
			<div>
				<el-table :data="billloglist" style="width:100%">
					<el-table-column prop="dt" :label="acbill.io==0?'收款日期':'付款日期'">
					</el-table-column>
					<el-table-column prop="money" :label="acbill.io==0?'收款金额':'付款金额'">
						<template slot-scope="scope">
							￥{{scope.row.money/100}} <el-tag v-if="scope.row.tp>0" type="success">抵</el-tag>
						</template>
					</el-table-column>
					<el-table-column prop="dt" label="登记人">
						<template slot-scope="scope">
							{{scope.row.name||scope.row.usern}}
						</template>
					</el-table-column>
					<el-table-column prop="create_time" label="登记时间">
					</el-table-column>
					<el-table-column prop="note" label="备注">
					</el-table-column>
					<el-table-column label="" width="100" v-if="acbill.payed<2">
						<template slot-scope="scope">
							<el-button size="mini" slot="reference" icon="el-icon-edit"
								@click.stop="editbilllog(scope.$index, scope.row)" circle style="margin-right: 10px;">
							</el-button>
							<el-popover placement="top" width="60" v-model="scope.row.visible">
								<p>确定删除吗？</p>
								<div style="text-align: right; margin: 0">
									<el-button size="mini" type="text" @click.stop="scope.row.visible = false">取消
									</el-button>
									<el-button type="primary" size="mini"
										@click.stop="delbilllog(scope.$index, scope.row)">确定
									</el-button>
								</div>
								<el-button size="mini" slot="reference" icon="el-icon-delete" circle>
								</el-button>
							</el-popover>

						</template>
					</el-table-column>
				</el-table>
			</div>
			<el-form :model="payForm" :rules="rules" ref="payForm" label-width="80px" class="demo-payForm"
				style="margin-top:20px;" empty-text="没有支付记录" load v-if="acbill.payed<2" :close-on-click-modal="false">
				<el-row>
					<el-col :span="6">
						<el-form-item label="付款日期" prop="paytime">
							<el-date-picker type="date" placeholder="选择日期" v-model="payForm.paytime"
								style="width: 100%;">
							</el-date-picker>
						</el-form-item>
					</el-col>
					<el-col :span="5">
						<el-form-item label="付款金额" prop="paymoney">
							<el-input v-model="payForm.paymoney"></el-input>
						</el-form-item>
					</el-col>
					<el-col :span="7">
						<el-form-item label="备注" prop="note">
							<el-input v-model="payForm.note" maxlength="100"></el-input>
						</el-form-item>
					</el-col>
					

					<el-col :span="6">
						<el-button type="primary" @click="submitpayfrom()" style="margin-left:10px;">
							{{payForm.id==''?'添加':'编辑'}}
						</el-button>
						<el-button type="default" @click="clearpayfrom()" style="margin-left:10px;"
							v-if="payForm.id!=''||payForm.tp>0">清除</el-button>
						<el-button v-if="acbill.io==0" @click="showdikou" type="success">查看可抵扣账单</el-button>
					</el-col>
				</el-row>

			</el-form>
			<!-- 可以抵扣的账单 -->
			<div v-if="isdikou&&acbill.io==0">
				<el-table :data="dikoulist" stripe>
					<el-table-column prop="ra1" label="账单日期">
					</el-table-column>
					<el-table-column prop="typeid" label="账单类型">
						<template slot-scope="scope">
							{{ paytypemap[scope.row.typeid].class_name }}
						</template>
					</el-table-column>
					<el-table-column prop="paymoney" label="可用金额">
						<template slot-scope="scope">
							{{ scope.row.candikou/100 }}
						</template>
					</el-table-column>
					<el-table-column  label="">
						<template slot-scope="scope">
							<el-button @click="chdikoubill(scope.row)">选择</el-button>
						</template>
					</el-table-column>
				</el-table>
			</div>
		</el-dialog>
		<el-dialog :visible.sync="isshowstar" width="500px" :append-to-body="true">

			<div>
			<div class="fcj_lc"><div>满意度：</div>
			<div><el-rate v-model="starvalue"></el-rate></div></div>
			<div style="text-align: center;margin:40px 0;"><el-popconfirm
							confirm-button-text='确认'
							cancel-button-text='取消'
							icon="el-icon-info"
							icon-color="red"
							title="确认完成收款吗？"
							@confirm="finishpay()"
							style="margin-right:20px;" 
						>
						<el-button type="primary" slot="reference">确认完成收款</el-button>
						</el-popconfirm></div>
			</div>
		</el-dialog>
		<!-- 发票登记 -->
		<el-dialog :visible.sync="isshowaddinvoice" width="800px" :close-on-click-modal="false" @close="closeaddinvoice">
			<div style="padding:10px;background-color: #f5f5f5;border-radius: 8px;margin:10px 0 20px 0;">
				<el-row>
					<el-col :span="12">
						<div>企业名称：{{acbill.company}}</div>
					</el-col>
					<el-col :span="12">
						<div v-if="acbill">{{(acbill.io==1?'已付金额：':'已收金额：')+Math.abs(acbill.paymoney)}}</div>
					</el-col>
				</el-row>
				<el-row>
					<el-col :span="12">
						<div v-if="acbill">{{(acbill.io==1?'应付金额：':'应收金额：')+Math.abs(acbill.amountmoney)}}</div>
					</el-col>
		
					<el-col :span="12">
						<div v-if="isshowaddinvoice">{{(acbill.io==1?'未付金额：':'未收金额：')+(acbill.io==1?-parseFloat(acbill.amountmoney-acbill.paymoney).toFixed(2):parseFloat(acbill.amountmoney-acbill.paymoney).toFixed(2))}}</div>
					</el-col>
		
				</el-row>
			</div>
				
			<el-form  label-width="100px" class="demo-payForm"
				style="margin-top:20px;"  load>
				
						<el-form-item label="发票号" prop="invoice">
							<el-input v-model="acbill.invoice" ></el-input>
							<!-- <div v-else>{{acbill.invoice}}</div> -->
						</el-form-item>
					
						<el-form-item label="备注" prop="invoice">
							<el-input v-model="acbill.invoice_note" ></el-input>
							<!-- <div v-else>{{acbill.invoice}}</div> -->
						</el-form-item>
						<el-form-item label="登记时间" prop="invoice">
							<el-date-picker type="month" placeholder="选择日期" value-format="yyyy-MM"  v-model="acbill.invoice_in_time" style="width: 200px;"></el-date-picker>

						</el-form-item>
						<el-form-item label="登记人" prop="invoice">
							{{acbill.invoicename||acbill.invoiceusern}}
						</el-form-item>
						<el-form-item label="图片" prop="pic">
							<div class="fcj_ll">
								<div>
									<el-upload :action="host+'upfile/'" :data="uploaddata" list-type="text" ref="uploadparkpic" name="upfile" :on-success="uploadparkpicsuccess" :auto-upload="true" :limit="1">
										<el-button type="default" size="medium" icon="el-icon-picture-outline">上传发票</el-button>
									</el-upload>
								</div>
								<div style="padding-left:10px;">
									<div v-for="(item,index) in acbill.invoice_pics" :key="item.id"><a  :href="host+item.fpath">{{item.fname}}</a> <el-button type="default" @click="delacbillfile(index)" size="small">删除</el-button></div>
								</div>
							</div>
							
						</el-form-item>
							
						
						<el-form-item label=" " prop="invoice">
								<div class="fcj_ll">
						<el-button type="primary" @click="submitinvoicefrom()"  >
							保存
						</el-button>
						
						

						<el-popconfirm
							title="确定要完成登记吗？"
							@confirm="submitinvoicefrom(1)"
							v-if="acbill.invoice_in_time&&acbill.invoicestate==0"
						>
							<el-button slot="reference">完成登记</el-button>
						</el-popconfirm>
						
						<div style="height: 40px;line-height: 40px;padding:0 10px;" v-if="acbill.invoicestate==1">已完成登记</div>
						
						</div>
					</el-form-item>
				</el-form>
					
		</el-dialog>
		<!-- 批量发票登记 -->
		<el-dialog :visible.sync="isshowbataddinvoice" width="800px" :close-on-click-modal="false">
			
				
			<el-form  label-width="100px" class="demo-payForm"
				style="margin-top:20px;"  load>
				
				
						<el-form-item label="发票号" prop="invoice">
							<el-input v-model="batinvoice.invoice" style="width:200px;" ></el-input>
							<!-- <div v-else>{{acbill.invoice}}</div> -->
						</el-form-item>
					
						<el-form-item label="备注" prop="invoice">
							<el-input v-model="batinvoice.invoice_note" ></el-input>
							<!-- <div v-else>{{acbill.invoice}}</div> -->
						</el-form-item>
					
						<el-form-item label="登记时间">
							<el-date-picker type="month" placeholder="选择日期" value-format="yyyy-MM"  v-model="batinvoice.invoice_in_time" style="width: 200px;"></el-date-picker>
							</el-form-item>
							
							<el-form-item label=" " prop="invoice">
						<el-button type="primary" @click="surebat()"  >
							保存
						</el-button>
					</el-form-item>
						
					
		
			</el-form>
		</el-dialog>
		<!-- 水电费数据导入 -->
		<el-dialog :visible.sync="isshowdatain"  title="水电费数据导入" width="600px">
			<el-form :model="uploadForm" :rules="uploadrules" ref="uploadForm" label-width="140px" class="demo-ruleForm">
				<el-form-item label="账单周期" prop="billtime">
					<el-date-picker type="month" placeholder="选择日期" v-model="uploadForm.billtime" style="width: 200px;">
					</el-date-picker>
				</el-form-item>
				<el-form-item label="收款账号" prop="paycount">
					<el-select v-model="uploadForm.paycount" placeholder="请选择收款账号">
						<el-option v-for="(item,index) in banks" :label="item.des" :value="item.id" :key="index">
							{{item.des}}
						</el-option>
					</el-select>
				</el-form-item>
				
				<el-form-item label="最迟付款日期" prop="endtime">
					<el-input-number controls-position="right" :step="1" :min="0"
						v-model="uploadForm.endtime" style="width:120px;"></el-input-number>
					<span>（收到账单的前N天）</span>
				</el-form-item>
				
				
			
				<el-form-item label="数据文件" prop="files" style="min-height: 50px;">
					<el-upload :action="host+'upfile/'" :show-file-list="true" list-type="text" ref="uploadfloorpic1" name="upfile"
						:on-success="uploadfloorpicsuccess" :on-change="filechange" :multiple="false" :limit="9" :file-list="fileList" :auto-upload="false" :data="uploaddata">
					
						<el-button type="primary" size="small" icon="el-icon-upload2">上传文件
						</el-button>
					
					</el-upload>
				</el-form-item>
			
				<el-form-item v-if="excelpath!=''">
					<div style="margin-top: 10px;"><a :href="excelpath" target="_blank">查看失败原因</a></div>
				</el-form-item>
				<el-form-item>
					<el-button type="primary" @click="upfont()" :loading="upefile" icon="el-icon-document" style="margin-top:50px;">保存</el-button>
				</el-form-item>
			
			
			</el-form>
		</el-dialog>

		<!-- 新建编辑账单 -->
		<el-dialog :visible.sync="isshowbillform" :class="['editbill']" width="800px" :close-on-click-modal="false">
			<el-descriptions title="" :column="1" size="medium">
				<el-descriptions-item label="合同编号" label-class-name="des-label" content-class-name="des-content">
					{{contract.number}}
				</el-descriptions-item>
				<el-descriptions-item label="客户名称" label-class-name="des-label" content-class-name="des-content">
					{{contract.company}}
				</el-descriptions-item>
				<el-descriptions-item label="租赁期限" label-class-name="des-label" content-class-name="des-content">
					{{contract.regist_time+'~'+contract.term}}
				</el-descriptions-item>
				<el-descriptions-item label="费用类型" label-class-name="des-label" content-class-name="des-content"
					v-if="acbill!=''">
					{{paytypemap[acbill.typeid]?paytypemap[acbill.typeid].class_name:''}}
				</el-descriptions-item>
				<el-descriptions-item label="账单周期" label-class-name="des-label" content-class-name="des-content"
					v-if="acbill!=''">
					{{acbill.ra1}}
				</el-descriptions-item>
				<el-descriptions-item label="导入批次号" label-class-name="des-label" content-class-name="des-content"
					v-if="type=='ele'">
					{{acbill.filenumber}}
				</el-descriptions-item>
				<el-descriptions-item label="账单金额(元)" label-class-name="des-label" content-class-name="des-content"
					v-if="type=='ele'">
					{{acbill.amountmoney}}
				</el-descriptions-item>
			</el-descriptions>
			<el-form :model="billForm" :rules="billrules" ref="ruleForm" label-width="140px" class="demo-ruleForm" @close='resetform()' :destroy-on-close="true">

				<div v-if="acbill==''">
					<el-form-item label="费用类型" prop="typeid">
						<el-select v-model="billForm.typeid" placeholder="请选择">
							<el-option
								v-for="(item,index) in paytype"
								:key="index"
								:label="item.class_name"
								:value="item.fid">{{item.class_name}}
							</el-option>
						</el-select>
						
					</el-form-item>
				</div>
				<el-form-item label="账单周期" prop="ra1">
					<el-date-picker v-model="billForm.ra1" type="daterange" range-separator="至"
						start-placeholder="开始日期" end-placeholder="结束日期">
					</el-date-picker>
				</el-form-item>
				
				<el-form-item label="付款周期" prop="cycle" v-if="acbill==''&&(billForm.typeid==87||billForm.typeid==89||billForm.typeid==297)">
					<div class="fcj_ll">
						<div>
							<el-input-number controls-position="right"  :step="1" :min="0" v-model="billForm.cycle" style="width:100px;"></el-input-number>
						</div>
						<div style="padding-left:10px;">
							个月一付 <span style="font-size: 12px;color:#999;margin-left:10px;">（单个账单不用填）</span>
						</div>
					</div>
				</el-form-item>
				<el-form-item :label="billForm.typeid==87?'月租金(元)':(billForm.typeid==89?'月物业费(元)':'其他费用')" prop="money" v-if="acbill==''&&billForm.cycle!=''&&(billForm.typeid==87||billForm.typeid==89||billForm.typeid==297)">
					<el-input-number controls-position="right" :controls="false" :step="0.01" :min="0" v-model="billForm.money"
						></el-input-number>
				</el-form-item>
				<div  v-if="acbill==''&&(billForm.typeid==87&&billForm.cycle!='')">
					<el-form-item label="合同期内租金递增" prop="dizeng">
						<el-radio-group v-model="billForm.dizeng" @change="dizengchange">
							<el-radio name="dizeng" label="1" value="1">有递增</el-radio>
							<el-radio name="dizeng" label="0" value="0">无递增</el-radio>
						</el-radio-group>
					</el-form-item>
					<el-form-item label="" v-if="billForm.dizeng==1">
					<div class="block" style="margin:0px;">
						<div class="tbtr" v-for="(item,index) in depositmorev" :key="index">
							<div class="fcj_ll">
								<div>
									递增后月租金
								</div>
								<div style="margin:0 10px;">
										<el-input-number controls-position="right" :controls="false" :step="0.1" :min="0" v-model="depositmorev[index]" style="width:80px;" />
									
								</div>
								<div>
									开始生效日期
								</div>
								<div style="margin:0 10px;">
										<el-date-picker v-model="depositmoredate[index]" type="date"
											style="width:140px" placeholder="选择日期" :picker-options="pickerOptions">
										</el-date-picker>
								</div>
								<div>
									<el-button @click="deldep(index)" icon="el-icon-delete">删除</el-button>
								</div>
							</div>
					
						</div>
						<div>
							<el-button @click="adddep" icon="el-icon-plus">添加</el-button>
						</div>
					</div>
					</el-form-item>
				</div>
				<el-form-item label="账单金额(元)" prop="amountmoney" v-if="type!='ele'&&billForm.cycle==''||acbill!=''">
					<el-input-number controls-position="right" :step="0.1"  v-model="billForm.amountmoney"
						style="width:200px;text-align: left;" :disabled="acbill.payed==2"></el-input-number>
				</el-form-item>
				

				<el-form-item label="收款账号" prop="paycount">
					<el-select v-model="billForm.paycount" placeholder="请选择收款账号" style="width:300px;">
						<el-option v-for="(item,index) in banks" :label="item.des" :value="index" :key="index">
							{{item.des}}
						</el-option>
					</el-select>
				</el-form-item>

				<el-form-item label="最迟付款日期" prop="endtime" v-if="type=='ele'">
					<el-input-number controls-position="right" :step="1" :min="0" v-model="billForm.fontday"
						style="width:100px;text-align: left;"></el-input-number>(收到账账单的第N天)
				</el-form-item>
				<el-form-item label="最迟付款日期" prop="endtime" v-if="type!='ele'&&billForm.cycle==''">
					<el-date-picker type="date" placeholder="选择日期" v-model="billForm.endtime" style="width: 200px;" >
					</el-date-picker>
				</el-form-item>

				<el-form-item label="备注说明" prop="note">
					<el-input type="textarea" v-model="billForm.note" style="width:500px;" show-word-limit
						:autosize="{ minRows: 3}" maxlength="255"></el-input>
				</el-form-item>
				<div class="block">
					<el-form-item label="单位名称" prop="company">
						<el-input v-model="billForm.taxcompany" style="width:400px;"></el-input>
					</el-form-item>
					<el-form-item label="税号" prop="taxnumber">
						<el-input v-model="billForm.taxnumber" style="width:400px;"></el-input>
					</el-form-item>
					<el-form-item label="地址及电话" prop="taxaddress">
						<el-input v-model="billForm.taxaddress" style="width:400px;"></el-input>
					</el-form-item>
					<el-form-item label="开户行及账号" prop="bankinfo">
						<el-input v-model="billForm.taxbankinfo" style="width:400px;"></el-input>
					</el-form-item>
				</div>

				<el-form-item>
					<el-button type="primary" @click="submitForm('ruleForm')" icon="el-icon-document">保存</el-button>
				</el-form-item>


			</el-form>
		</el-dialog>

		<!-- 新建编辑负向账单 -->
		<el-dialog :visible.sync="isshowsubbillform" title="负项账单" :class="['editbill']" width="800px" :close-on-click-modal="false">
			<el-descriptions title="" :column="1" size="medium">
				<el-descriptions-item label="合同编号" label-class-name="des-label" content-class-name="des-content">
					{{contract.number}}
				</el-descriptions-item>
				<el-descriptions-item label="客户名称" label-class-name="des-label" content-class-name="des-content">
					{{contract.company}}
				</el-descriptions-item>
				<el-descriptions-item label="租赁期限" label-class-name="des-label" content-class-name="des-content">
					{{contract.regist_time+'~'+contract.term}}
				</el-descriptions-item>

				
				<el-descriptions-item label="费用类型" label-class-name="des-label" content-class-name="des-content"
					v-if="acbill!=''">
					{{paytypemap[acbill.typeid]?paytypemap[acbill.typeid].class_name:''}}
				</el-descriptions-item>
				<el-descriptions-item label="账单周期" label-class-name="des-label" content-class-name="des-content"
					v-if="acbill!=''">
					{{acbill.ra1}}
				</el-descriptions-item>
				<el-descriptions-item label="导入批次号" label-class-name="des-label" content-class-name="des-content"
					v-if="type=='ele'">
					{{acbill.filenumber}}
				</el-descriptions-item>
				<el-descriptions-item label="账单金额(元)" label-class-name="des-label" content-class-name="des-content"
					v-if="type=='ele'">
					{{acbill.amountmoney}}
				</el-descriptions-item>
			</el-descriptions>
			<el-form :model="negativeformdata" :rules="billnegative" ref="negativeForm" label-width="140px"  @close='resetform()' :destroy-on-close="true">

				<div v-if="acbill==''">
					<el-form-item label="费用类型" prop="typeid">
						<el-select v-model="negativeformdata.typeid" placeholder="请选择">
							<el-option
								v-for="(item,index) in paytype"
								:key="index"
								:label="item.class_name"
								:value="item.fid">{{item.class_name}}
							</el-option>
						</el-select>
						
					</el-form-item>
				</div>	

				<el-form-item label="账单周期" prop="ra1">
					<el-date-picker v-model="negativeformdata.ra1" type="daterange" range-separator="至"
						start-placeholder="开始日期" end-placeholder="结束日期" >
					</el-date-picker>
				</el-form-item>
				

				
				<el-form-item label="账单金额(元)" prop="amountmoney" >
					<el-input-number controls-position="right" :step="0.1"  v-model="negativeformdata.amountmoney"
						style="width:200px;text-align: left;" ></el-input-number>
				</el-form-item>


				<el-form-item label="备注说明" prop="note">
					<el-input type="textarea" v-model="negativeformdata.note" style="width:500px;" show-word-limit
						:autosize="{ minRows: 3}" maxlength="255"></el-input>
				</el-form-item>
				
				<el-form-item>
					<el-button type="primary" @click="negative('negativeForm')" icon="el-icon-document">保存</el-button>
				</el-form-item>


			</el-form>
		</el-dialog>

		
		<!-- 账单确认 -->
		<el-dialog :visible.sync="isshowsend" title="账单确认" width="600px">
			<el-alert
			title="已确认所选账单数据，开始结算！请选择是否将电子账单发给租户"
			type="info"
			:closable="false"
			show-icon>
			</el-alert>
			
			<div style="text-align: center;padding:50px;">
				<el-radio-group v-model="issend">
					<el-radio name="issure" label="1" value="1">确认不发送</el-radio>
					<el-radio name="issure" label="2" value="2">确认发送但不通知</el-radio>
					<el-radio name="issure" label="3" value="3">确认发送并通知</el-radio>
				</el-radio-group>
			</div>
			<div style="text-align: center;">
				<el-button type="primary" @click="send()">确定</el-button>
				<el-button @click="isshowsend=false">取消</el-button>
			</div>
		</el-dialog>

		<!-- 退租说明 -->
		<el-dialog :visible.sync="isrerent"  width="800px">
			<div slot="title" style="color:#121212;font-size:16px;">合同退租</div>
			<div style="height: 350px;">
				<div style="padding:20px;background-color: #f5f5f5;border-radius: 8px;margin:-20px 0 20px 0;">
					<el-row>
						<el-col :span="12">
							<div>合同编号：{{contract.number}}</div>
						</el-col>
						<el-col :span="12">
							<div>客户名称：{{contract.company}}</div>
						</el-col>
					</el-row>
					<el-row>
						<el-col :span="12">
							<div>租赁期限：{{contract.regist_time+'~'+contract.term}}</div>
						</el-col>
						<el-col :span="12">
							<div>租赁物业：{{contract.office+((contract.office!=''&&contract.address!='')?'，':'')+contract.address}}</div>
						</el-col>
					</el-row>
				</div>
				<el-form :model="backform" :rules="backrules" ref="backform" label-width="160px">
					<el-form-item label="退租日期" prop="date">
						<el-date-picker type="date" placeholder="选择日期" v-model="backform.date" style="width: 150px;">
						</el-date-picker>
					</el-form-item>
					
					
					<el-form-item label="退租原因" prop="backnote">
						<el-input type="textarea" v-model="backform.backnote"></el-input>
					</el-form-item>
					<el-form-item>
						<el-button type="primary" @click="rerent()">确认</el-button>

						<el-button @click="cback">取消</el-button>
					</el-form-item>
				</el-form>
			</div>
		</el-dialog>

		<!--  -->
		<el-dialog :visible.sync="isshowelec" title="存在已结束或作废的合同，确定继续提交吗？" :before-close="closeele" width="900px" append-to-body>
			<div style="max-height: 400px;overflow-y: auto;padding:20px;margin-bottom:20px;">
				<div v-for="(item,index) in outcontract" :key="index" class="fcj_lr" style="border-bottom:1px solid #efefef;padding-bottom:5px;margin-bottom:5px;">
					<div class="fcj_ll"><div style="margin-right:10px;">{{ item.iii }}</div><div>{{ item.company }}（id：{{item.id}}）</div> <div style="color:#aaa;">{{ item.statename }}</div></div>
					<div style="font-weight: bold;" v-if="item.toid">续租合同：{{ item.tocompany }}（id：{{ item.toid }})</div>
				</div>
			</div>
			<div class="fcj_lc"><el-button type="primary" @click="uploadsubmitForm(1)" :loading="upelestate">已知上述情况，并仍继续操作</el-button><el-button style="margin-left:10px" @click="closeele">取消</el-button></div>
		</el-dialog>

		<div v-if="downbill!=''" style="height: 0px;overflow: hidden;"><iframe :src="downbill" frameborder="0"></iframe></div>
		
	</div>
</template>

<script>
	export default {
		data() {
			return {
				upefile:false,
				upelestate:false,
				isshowelec:false,
				outcontract:[],
				downbill:'',
				batinvoice:{

				},
				dataDetails:1,
				isdikou:false,
				dikoulist:[],
				backform: {
					comname: '',
					backnote: "",
					date: "",
					do: "update",
					id: ""
				},
				isrerent:false,
				fileList:[],
				pivisible:false,
				pivisiblemsg:false,
				paytime:'',
				isshowbat:false,
				depositmorev: [''],
				depositmoredate: [],
				
				breadcrumb:[],
				isshowstar:false,
				starvalue:'',
				excelpath:'',
				uploadForm:{
					billtime:'',
					paycount:'',
					endtime:'5',
					files:[]
				},
				uploadrules:{
					billtime: [{
						required: true,
						message: '请输入账单日期',
						trigger: 'change'
					}],
					paycount: [{
						required: true,
						message: '请选择收款账号',
						trigger: 'change'
					}],
					endtime: [{
						required: true,
						message: '请输入最迟付款日期',
						trigger: 'change'
					}]
				},
				isshowdatain:false,
				acrowid:'',
				issend:'1',
				search: {
					office_area:'',
					company: '',
					typeid: '',
					state: '',
					bankid: '',
					payed: [],
					readstate: '',
					invoicestate: '',
					billtimestart: '',
					month: '',
					filenumber: '',
					billtime: [],
					rent:0,
					minmoney:'',
					maxmoney:'',
					io:'',
					dikou:'',
					bankname:'',
					invoice_in_time:'',
					personalid:'',
					payerid:''
				},
				editbill_old: false,
				isshowaddinvoice:false,
				isshowbataddinvoice:false,
				isshowbillform: false,
				isshowsubbillform:false,
				isshowinfo: false,
				billloglist: [],
				isshowaddlog: false,
				isshowsend:false,
				contractid: '',
				chbankid: '',
				chbankshow: false,
				multipleSelection: [],
				editbill: false,
				billstate: [],
				paystate: [],


				acbill: {
					file:{fname:''}
				},
				deal: false,
				date1: '',
				date2: '',
				banks: [],
				res: [],

				pgno: 1,
				pgcount: 0,
				pgsize: 13,

				payForm: {
					id: '',
					paymoney: '',
					paytime: '',
					tp:'',
					note:''
				},
				payrule: {
					paymoney: [{
						required: true,
						message: '请输入金额',
						trigger: 'blur'
					}],
					paytime: [{
						required: true,
						message: '请输入支付时间',
						trigger: 'blur'
					}]
				},
				ruleForm: {
					price: '',
					name: '',
					region: '',
					date1: '',
					date2: '',
					type: [],
					bankid: '',

					rentprice: '',
					rent: '',
					otherrent: '',
					property: '',
					rentdeposit: '',
					propertydeposit: '',
					amountmoney: '',
					note: '',
					payed: '',
					paymoney: '',
					notpay: '',
					star: '',
					paytime: ''
				},
				backrules:{
					date:[{
						required: true,
						message: '请选择退租日期',
						trigger: 'change'
					}],
					backnote:[{
						required: true,
						message: '请输入退租原因',
						trigger: 'change'
					}],
				},
				rules: {
					name: [{
						required: true,
						message: '请输入退租原因',
						trigger: 'blur'
					}],
					date1: [{
						type: 'date',
						required: true,
						message: '请选择日期',
						trigger: 'change'
					}],
					date2: [{
						type: 'date',
						required: true,
						message: '请选择时间',
						trigger: 'change'
					}]
				},
				billrules: {
					typeid:[{
						required: true,
						message: '请选择费用类型',
						trigger: 'change'
					}],
					ra1:[{
						required: true,
						message: '请选择账单周期',
						trigger: 'change'
					}],
					paycount:[{
						required: true,
						message: '请选择收款账号',
						trigger: 'change'
					}]
				},
				billnegative:{
					typeid:[{
						required: true,
						message: '请选择费用类型',
						trigger: 'change'
					}],
					amountmoney:[{
						required: true,
						message: '请填写账单金额'
					}]
				},
				billForm: {
					money:'',
					cycle:'',
					dizeng:'',
					contractid: '',
					customerid: '',
					company: '',
					paycount: '',

					typeid: '',
					ra1: [],
					amountmoney: undefined,
					bankid: '',
					endtime: '',
					note: '',
					taxcompany: '',
					taxnumber: '',
					taxaddress: '',
					taxbankinfo: '',
					fontday:'',
				},

				info: {},
				bill: { //账单详情

				},
				paytype: [],
				contract: {},
				paytypemap: {},

				history: 0,
				type: '',
				host:'',
				uploaddata:{
					pusern:'',
					backapi:1,
					sys:localStorage.getItem('sys')
				},
				pickerOptions: {
					disabledDate: (time) => {
						return time.getTime() > (new Date(this.contract.term)).getTime() || time.getTime() < (new Date(this.contract.regist_time)).getTime()-86400000;
					}
				},
				pickerOptions1: {
					disabledDate: (time) => {
						return time.getTime() > (new Date(this.contract.term)).getTime() ;
					}
				},
				allamount:0.00,
				paymoney:0.00,
				notpay:0.00,
				allreturnm:0.00,
				returnm:0.00,
				semoney:'',
				down:0,
				editnegative:false,
				negativeformdata:{
					contractid:'',
					billids:[],
					typeid:'',
					ra1:[],
					amountmoney:'',
					endtime:'',
					note:''
				},
				loading:false,
				backtag:false,	//是否正在退租,
				canmove:1,
				banksmap:[],
				showqu:false,
				officearea:[],
				staff:[]
			}
		},

		beforeRouteEnter(to,from,next) {
			
			next(vm=>{
				var title='账单列表';
				if(vm.$route.query.type=='rent'){
					title='租金物业费';
				}else if(vm.$route.query.type=='ele'){
					title='水电费';
				}else if(vm.$route.query.type=='invoice'){
					title='发票登记跟踪';
				}else if(vm.$route.query.type=='history'){
					title='历史账单';
				}
				vm.$root.myrouter=vm.$breadcrumb(vm.$root.myrouter,to,title)
			})
		},
		activated:function(){
			this.downbill='';
			this.tts();

		},
		mounted: function() {
			var n = new Date();
			var nowtime=n.getFullYear() + '-' + (n.getMonth() + 1) + '-' + n.getDate()
			this.uploadForm.billtime=nowtime
			this.uploaddata.pusern=localStorage.getItem('pusern');
			this.breadcrumb=this.$root.myrouter
			this.host = this.$host
			console.log(this.host)
			if(this.host=='https://admin.kefan.com/zhfty/'||this.host=='http://localhost/zhfty/'){
				this.showqu=true;
			}

		},
		watch:{
			
			'$route'(to,from){
				
				if(to.path==from.path&&to.fullPath!=from.fullPath){
					this.pgno=1;
					console.log('new')
					this.search= {
						company: '',
						typeid: '',
						state: '',
						bankid: '',
						payed: [],
						readstate: '',
						invoicestate: '',
						billtimestart: '',
						month: '',
						filenumber: '',
						billtime: [],
						rent:0,
						minmoney:'',
						maxmoney:'',
						io:'',
						dikou:'',
						bankname:'',
						invoice_in_time:''
					}
					this.tts();
				}
			}
		},
		methods: {
			delacbillfile(id){
				this.$ajax({
					url: 'bill/',
					data: {
						do: 'delbillpic',
						id:this.acbill.id,
						fileid:this.acbill.invoice_pics[id].id
					}
				}).then(res => {
					if(res.error==0){
						this.acbill.invoice_pics.splice(id,1)
					}
				})
			},
			closeele(){
				this.uploadForm.files=[];
				this.isshowelec=false;
			},
			uploadparkpicsuccess(response) {
				if(response.error==1){
					alert('文类型不正确');
					return;
				}
				
				this.$ajax({
					url: 'bill/',
					data: {
						do: 'updatebillpic',
						fileid: response.fileid,
						id:this.acbill.id
					}
				}).then(res => {
					if(res.error==0){
						this.acbill.invoice_pics.push({'id':response.fileid,'fname':response.fname,'fpath':response.fpath})
						console.log(this.acbill);
					}
				})
				
				this.$refs.uploadparkpic.clearFiles();
			},
			closeaddinvoice(){
				this.acbill='';
			},
			oo(){
				console.log('ooo')
			},
			goBack(){
				this.dataDetails=2;
				console.log('back')
			},
			tts(){
				var tag=0;
				if(this.$route.query.type!=undefined){
					if(this.type!=this.$route.query.type){
						this.pgno=1
						tag=1;
					}
				}
				this.type ='';
				if(this.$route.query.type){
					this.type = this.$route.query.type
				}
				
				
				
				
				if (this.$route.query.id != undefined) {
					if(this.contractid!=this.$route.query.id){
						tag=1;
					}
					this.contractid = this.$route.query.id;
				}else{
					this.contractid ='';
				}
				if (this.$route.query.back != undefined) {
					this.backtag = true;
				}else{
					this.backtag=false;
				}

				if(tag==1){
					this.search= {
						company: '',
						typeid: '',
						state: '',
						bankid: '',
						payed: [],
						readstate: '',
						invoicestate: '',
						billtimestart: '',
						month: '',
						filenumber: '',
						billtime: [],
						rent:0,
						minmoney:'',
						maxmoney:'',
						io:'',
						dikou:'',
						bankname:'',
						invoice_in_time:'',
						personalid:''
					}
				}

				if(this.type=='ele'){
					this.search.typeid=[91];
					this.search.state=0;
				}else if(this.type=='invoice'){
					this.search.state=1;
				}else if(this.type=='rent'){
					this.search.state=0
					this.search.rent=1
					var e=new Date();
					if(this.host=='https://admin.kefan.com/zhfty/'){
						e.setDate(e.getDate()+30);
					}else{
						e.setDate(e.getDate()+15)
					}
					
					
					this.search.billtime=['',e.getTime()]
				}else if(this.type=='history'){
					//是指发票状态是已结算，且收款状态是已收款，发票状态是已登记的账单
					this.search.invoicestate=1
					this.search.payed=[2]
					this.search.state=2
				}

				this.getdata();
			},
			cellstyle(row){
				if(row.row.io==1){
					return 'background-color:#faecd8;'
				}
			},
			rerent() {

				this.$confirm('如需补录账单，请先手工创建账单并确认结算后再退租', '提示', {
					confirmButtonText: '直接退租',
					cancelButtonText: '补录账单',
					type: 'warning'
				}).then(() => {
					this.back();
				}).catch(() => {
					this.isrerent=false;
				});
			
			},
			back(){
				this.$refs['backform'].validate((valid) => {
					if (valid) {
						this.backform.id = this.contractid
						this.$ajax({
							url: 'contract/',
							data: this.backform,
						}).then(res => {
				
							if (res.error ==0){
								this.isrerent=false;
							}
							this.$alert(res.msg, '提示', {
								confirmButtonText: '确定'
							});
						})
					}
				})
			},
			menuClick(v){
				if(v[0]==1){
					this.gobillinfo(v[1]);
				}else if(v[0]==2){
					this.showbillform(v[1],v[2])
				}else if(v[0]==3){
					this.showaddlog(v[2],v[1])
				}else if(v[0]==4){
					this.showaddinvoice(v[2],v[1])
				}else if(v[0]==5){
					this.zuofei(v[2],v[1])
				}else if(v[0]==6){
					this.showsubbillform(v[2],v[1])
				}else if(v[0]==7){
					this.setbad(v[2],v[1])
					// this.deleledata(v[2],v[1])
				}
				
			},
			setbad(v,t){
				this.$ajax({
					url: 'bill/',
					data: {
						do: 'baddebts',
						id: t.id
					}
				}).then(res => {
					t.isbad=res.data;
					
				})
			},
			cback(){
				this.$refs['backform'].resetFields();
				this.isrerent=false
			},
			chdikoubill(v){
				this.payForm.paymoney=v.candikou/100
				this.payForm.tp=v.id;
				this.payForm.note=v.note;
				this.payForm.id='';
			},
			showdikou(){//显示可以抵扣的账单
				this.isdikou=true;
				this.$ajax({
					url: 'bill/',
					data: {
						'do': 'dikoulist',
						'contractid':this.acbill.contractid
					},
				}).then(res => {
					if(res.error==0){
						for(var i=0;i<res.data.c;i++){
							if(res.data.res[i].id==this.acbill.id){
								res.data.res.splice(i,1);
								break;
							}
						}
						this.dikoulist=res.data.res
					}
				});
			},
			sortchange(e){
				this.search.sort=[e.prop,e.order];
				this.page(1);
			},
			outbill(){
				this.down=1;
				this.getdata();
			},
			chcontrctid(v){
				// this.search={
				// 	company: '',
				// 	typeid: '',
				// 	state: '',
				// 	bankid: '',
				// 	payed: '',
				// 	readstate: '',
				// 	invoicestate: '',
				// 	billtimestart: '',
				// 	month: '',
				// 	filenumber: '',
				// 	billtime: [],
				// 	rent:0,
				// 	minmoney:'',
				// 	maxmoney:'',

				// }
				this.$router.push('bill?id='+v)
				// this.type='';
				// this.contractid=v;
				// this.page(1)
			},
			downloadbill(){
				if (this.multipleSelection.length == 0) {
					this.$alert('请至少选择一条修改', '提示', {
						confirmButtonText: '确定'
					});
					return
				}
				var po = [];
				for (var i = 0; i < this.multipleSelection.length; i++) {
					po.push(this.multipleSelection[i].id);
				}
				console.log(po.join(','))
				var sys=localStorage.getItem('sys')
				if(!sys){
					sys=1
				}
				this.downbill=this.host+'html/billinfo.html?ids=['+po.join(',')+']&sys='+sys+'&pusern=1,0404fc6888b503e8bb8f9b6f4f6aaed6&r='+Math.random();
			},
			submitbatpayfrom(){
				if (this.multipleSelection.length == 0) {
					this.$alert('请至少选择一条修改', '提示', {
						confirmButtonText: '确定'
					});
					return
				}
				var po = [];
				for (var i = 0; i < this.multipleSelection.length; i++) {
					po.push(this.multipleSelection[i].id);
				}
				this.$ajax({
					url: 'bill/',
					data: {
						'do': 'allfinish',
						'po': po,
						'paytime':this.paytime
					},
				}).then(res => {
					if(res.error==0){
						this.page(this.pgno)
						this.isshowbat=false
					}
					this.$alert(res.msg, '提示', {
						confirmButtonText: '确定'
					});
				});
			},
			showbat(){
				this.isshowbat=true;
			},
			showbatinvoice(){//批量发票登记
				this.isshowbataddinvoice=true;
			},
			surebat(){
				this.$confirm('确定要批量登记吗?', '提示', {
					confirmButtonText: '确定',
					cancelButtonText: '取消',
					type: 'warning'
				}).then(() => {
					var po = [];
					if (this.multipleSelection.length == 0) {
						this.$alert('请至少选择一条账单', '提示', {
							confirmButtonText: '确定'
						});
						return;
					}
					for (var i = 0; i < this.multipleSelection.length; i++) {
						po.push(this.multipleSelection[i].id);
					}
					this.$ajax({
						url: 'bill/',
						data: {
							do: 'updatebillinvoice',
							formdata:this.batinvoice,
							po:po,
							'type':1
						}
					}).then(res => {
						if(res.error==0){
							this.page(this.pgno)
						}
						this.$message({
							message:res.msg,
							type:'success'
						});
					});
				}).catch(() => {          
				});
			},
			//递增设置
			adddep: function() {
				var depositmore = this.depositmorev;
				depositmore[depositmore.length] = ''
				this.depositmorev = [];
				this.depositmorev = depositmore;
			},
			deldep(v) {
				if(this.depositmorev.length==1){
					return;
				}else{
					this.depositmorev.splice(v, 1);
					this.depositmoredate.splice(v, 1);
				}
			},
			dizengchange(v) {},
			
			resetform(){
				this.$refs['ruleForm'].resetFields();
			},
			upfont(){
				
				this.$refs['uploadForm'].validate((valid) => {
					if (valid) {
						this.upefile=true;
						this.$refs.uploadfloorpic1.submit();
					}
				})
			},
			filechange(file,fs){
				this.fileList=fs;
			},
			uploadsubmitForm(v){
				
				this.$refs['uploadForm'].validate((valid) => {
					if (valid) {
						this.upelestate=true;
						this.$ajax({
							url: 'bill/',
							data: {
								do: 'updateele',
								data:this.uploadForm,
								ok:v?1:''
							}
						}).then(res => {
							this.upelestate=false;
							if(v){
								this.uploadForm.files=[];
								this.isshowelec=false
							}
							if(res.error==0){
								this.excelpath=''
								this.isshowdatain=false
								this.isshowelec=false;
								this.page(this.pgno);
								this.$alert(res.msg, '提示', {
									confirmButtonText: '确定'
								});
							}else if(res.error==1){
								this.excelpath=this.host+res.data
								this.uploadForm.files=[];
								this.$alert(res.msg, '提示', {
									confirmButtonText: '确定'
								});
							}else if(res.error==2){
								
								this.outcontract=res.data;
								this.showelealert();
							}
							this.$refs.uploadfloorpic1.clearFiles();
							
						})
					}
				})
			},
			showelealert(){
				this.isshowelec=true;
			},
			uploadfloorpicsuccess(response) {
				this.uploadForm.files.push(response)
				if(this.uploadForm.files.length==this.fileList.length){
					this.upefile=false;
					this.uploadsubmitForm();
				}
				
			},
			downexcel(){

				this.$ajax({
					responseType:'blob',
					url: 'bill/',
					data: {
						'do': 'downmodel'
					}
				}).then(res => {
					let url=window.URL.createObjectURL(new Blob([res]));
					let link=document.createElement('a')
					link.download='水电费模板.xls'
					link.href=url
					link.click()
					return
				});
				//window.open(this.host+'images/model.xls')
			},
			showeledatain(id,v){
				this.isshowdatain=true
			},
			deleledata(v,row){
				if(v=='s'){
					this.$confirm('确定要删除吗?', '提示', {
						confirmButtonText: '确定',
						cancelButtonText: '取消',
						type: 'warning'
					}).then(() => {
						this.deleledata();
					}).catch(() => {          
					});
					return;
				}
				var po = [];
				if(v!=undefined){
					po.push(row.id);
				}else{
					if (this.multipleSelection.length == 0) {
						this.$alert('请至少选择一条账单', '提示', {
							confirmButtonText: '确定'
						});
						return;
					}
					for (var i = 0; i < this.multipleSelection.length; i++) {
						po.push(this.multipleSelection[i].id);
					}
				}
				this.$ajax({
					url: 'bill/',
					data: {
						'do': 'del',
						'po':po
					},
				}).then(res => {
					if(res.error==0){
						this.page(this.pgno)
					}
					this.$message({
						message:res.msg,
						type:'success'
					});
				})
				
			},
			showaddinvoice(id,v){
				this.isshowaddinvoice=true
				this.acrowid=id;
				this.acbill =JSON.parse(JSON.stringify(v));
				
			},
			submitinvoicefrom(v){
				var type=0;
				if(v){
					type=1;
				}
				this.$ajax({
					url: 'bill/',
					data: {
						do: 'updatebillinvoice',
						id: this.acbill.id,
						type:type,
						formdata:this.acbill

					}
				}).then(res => {
					if(res.error==0){
						if(v!=1){
							this.res[this.acrowid].invoice=this.acbill.invoice
							
							this.res[this.acrowid].invoicename=res.data.name||res.data.usern
							this.res[this.acrowid].invoice_in_time=res.data.date
						}else{
							this.res[this.acrowid].invoicestate=1
							this.page(this.pgno)
						}
					}
					
					this.$message({
						message: res.msg,
						type: res.error==0?'success':'warning'
					})
					
				})
			},
			finishpay(){
				this.$ajax({
					url: 'bill/',
					data: {
						'do': 'finish',
						'star':this.starvalue,
						id: this.acbill.id,
					},
				}).then(res => {
					if(res.error==0){
						this.page(this.pgno)
						this.isshowaddlog=false
						this.isshowstar=false
					}
					this.$alert(res.msg, '提示', {
						confirmButtonText: '确定'
					});
				})
			},
			goini(){
				this.$router.push('contractinfo?ini=1&id='+this.contractid)
			},
			gobillinfo(v) {
				this.$router.resolve({
					name:'bill',
					query:{id:v.id},
					
				})
				this.$router.push('/billinfo?id=' + v.id)
			},
			showsubbillform(v,t){
				this.isshowsubbillform = true;
				if(t.io==1){//修改负向账单
					this.$ajax({
							url: 'contract/',
							data: {
								'do': 'info',
								id: t.contractid,
							},
						}).then(res => {
							this.contract = res.res[0]
							// if (this.contract.state === '0') {
								
								this.$nextTick(()=>{
									this.$refs['negativeForm'].resetFields();
									this.acbill =t;
									this.editnegative=true;
									this.editbill = true;
									this.negativeformdata = JSON.parse(JSON.stringify(t));
									this.negativeformdata.paycount=parseInt(this.negativeformdata.paycount);
									this.negativeformdata.amountmoney=-this.negativeformdata.amountmoney;
									this.negativeformdata.cycle='';
									this.negativeformdata.ra1=this.negativeformdata.ra1.replace(/年|月/gi,'-').replace(/日/g,'').split('至');
								})
							// } else {
								
							// }
						})
				}else{//添加新负向账单初始化数据
					this.acbill=t;

					this.editnegative=false;
					this.editbill = false;
					//this.acbill = '';

					var ra1=1;
					this.$nextTick(()=>{
						this.$refs['negativeForm'].resetFields();

						this.negativeformdata = {
							money:'',
							cycle:'',
							dizeng:'',
							contractid: this.contract.id,
							customerid: this.contract.customerid,
							company: this.contract.company,
							paycount: parseInt(this.contract.bankid),
							typeid: t.typeid,
							ra1: t.ra1.replace(/(年|月)/gi,'-',).replace(/日/gi,'').split('至'),
							//amountmoney: t.amountmoney,
							amountmoney: t.paymoney.add(t.offset),
							endtime: '',
							note: '',
							fontday:'',
							id:t.id,
							io:t.io
						};
					})
				}
				
				
			},
			showbillform(v,rowindex) {
				
				if(v&&v.io==1){
					this.showsubbillform(rowindex,v);
					return;
				}
				//if (this.contractid == '' || this.contract.state === '0') {
					if (v) { //修改账单
						this.$ajax({
							url: 'contract/',
							data: {
								'do': 'info',
								id: v.contractid,
							},
						}).then(res => {
							this.contract = res.res[0]
							// if (this.contract.state === '0') {
								
								this.isshowbillform = true;
								this.$nextTick(()=>{
									this.resetform()
									this.acbill = v;
									
									this.editbill = true;
									this.billForm = JSON.parse(JSON.stringify(v));
									this.billForm.paycount=parseInt(this.billForm.paycount);
									this.billForm.cycle='';
									console.log(this.billForm);
									this.billForm.ra1=this.billForm.ra1.replace(/年|月/gi,'-').replace(/日/g,'').split('至');
								})
							// } else {
								
							// }
						})

					} else { //添加新账单初始化数据
						var msg='';
						// if(this.contract.state==1){
						// 	msg='合同为预签状态不能新建账单'
						// }else if(this.contract.state==2){
						// 	msg='合同已作废不能新建账单'
						// }else if(this.contract.state==3){
						// 	msg='合同已结束不能新建账单'
						// }
						if(msg!=''){
							this.$alert(msg, '提示', {
								confirmButtonText: '确定'
							});
							return
						}
						this.editbill = false;
						this.acbill = '';
						
						this.isshowbillform = true;
						this.$nextTick(()=>{
							this.resetform()
							this.billForm = {
								money:'',
								cycle:'',
								dizeng:'',
								contractid: this.contract.id,
								customerid: this.contract.customerid,
								company: this.contract.company,
								paycount: parseInt(this.contract.bankid),
								typeid: '',
								ra1: [],
								amountmoney: undefined,
								endtime: '',
								note: '',
								taxcompany: this.customer.taxcompany,
								taxnumber: this.customer.taxnumber,
								taxaddress: this.customer.taxaddress,
								taxbankinfo: this.customer.taxbankinfo,
								fontday:''
							};
						})
					}

				// } else {
				// 	if(this.contract.state === '1'){
				// 		this.$alert('预签合同请先进行账单初始化！', '提示', {
				// 			confirmButtonText: '确定'
				// 		});
				// 	}else{
				// 		this.$alert('合同已作废或结束，不能新建账单！', '提示', {
				// 			confirmButtonText: '确定'
				// 		});
				// 	}
				// }

			},

			backstate(id,v) {
				var po = [];
				if(id){
					po.push(v.id);
				}else{
					if (this.multipleSelection.length == 0) {
						this.$alert('请至少选择一条账单', '提示', {
							confirmButtonText: '确定'
						});
						return;
					}
					for (var i = 0; i < this.multipleSelection.length; i++) {
						po.push(this.multipleSelection[i].id);
					}
				}
				this.$confirm('确认要退回吗？', '确认信息', {
						distinguishCancelAndClose: true,
						confirmButtonText: '确认',
						cancelButtonText: '取消'
					})
					.then(() => {
						
						this.$ajax({
							url: 'bill/',
							data: {
								do: 'backstate',
								po: po
							}
						}).then(res => {
							this.$alert(res.msg, '提示', {
								confirmButtonText: '确定'
							});
							this.getdata();

						})
					})
					.catch(action => {

					});


			},
			showaddlog(id,v) {
				this.acrowid=id;
				this.isshowaddlog = true;
				this.dikoulist=[];
				this.isdikou=false;
				this.acbill = v;
				this.clearpayfrom();
				this.$ajax({
					url: 'bill/',
					data: {
						do: 'billlog',
						id: this.acbill.id
					}
				}).then(res => {
					if (res.c > 0) {
						this.billloglist = res.res
					} else {
						this.billloglist = []
					}
				})

			},
			clearpayfrom() {
				this.payForm = {
					id: '',
					paymoney: '',
					paytime: '',
					note:'',
					tp:0
				}
				this.isdikou=false;
				this.dikoulist=[];
			},
			editbilllog(id, row) {
				this.payForm.id = row.id
				this.payForm.paymoney = Math.abs(row.money)/100;
				this.payForm.note=row.note
				this.payForm.paytime = row.dt
			},
			delbilllog(id, row) {
				this.$ajax({
					url: 'bill/',
					data: {
						do: 'delbilllog',
						id: row.id,
						billid: row.billid
					}
				}).then(res => {
					if (res.c > 0) {
						this.billloglist = res.res
					} else {
						this.billloglist = []
					}
					this.acbill.paymoney = res.paymoney;
					this.acbill.notpay = res.notpay
					this.acbill.offset = res.offset
					if(this.isdikou){
						this.showdikou();
					}
				})
			},
			handleSelectionChange(val) {
				this.multipleSelection = val;
				if(val.length>0){
					var m=0;
					for(let i=0;i<val.length;i++){
						m+=parseFloat(val[i].amountmoney);
					}
					this.semoney=m.toFixed(2);
				}else{
					this.semoney='';
				}
			},
			showsend(){
				this.isshowsend=true
			},
			send(arg) {
				if(arg){
					this.issend=arg
				}
				var po = [];
				if (this.multipleSelection.length == 0) {
					this.$alert('请至少选择一条记录进行操作', '提示', {
						confirmButtonText: '确定'
					});
					return;
				}
				for (var i = 0; i < this.multipleSelection.length; i++) {
					po.push(this.multipleSelection[i].id);
				}
				this.$ajax({
					url: 'bill/',
					data: {
						do: 'send',
						po: po,
						st:this.issend>1?1:0,
						msg:this.issend==3?1:0
					}
				}).then(res => {
					this.isshowsend=false
					this.$alert(res.msg, '提示', {
						confirmButtonText: '确定'
					});
					this.page(this.pgno)

				})
			},
			updatebank(v) {
				if (this.multipleSelection.length == 0) {
					this.$alert('请至少选择一条修改', '提示', {
						confirmButtonText: '确定'
					});
					return
				}
				if (v) {
					this.chbankshow = true;
				} else {

					var po = [];
					for (var i = 0; i < this.multipleSelection.length; i++) {
						po.push(this.multipleSelection[i].id);
					}
					this.$ajax({
						url: 'bill/',
						data: {
							do: 'updatebank',
							po: po,
							bankid: this.chbankid
						}
					}).then(res => {
						this.$alert(res.msg, '提示', {
							confirmButtonText: '确定'
						});
						this.chbankshow=false
					})
				}
			},
			edit(v) {

				this.editbill = true;
				this.acbill = v;
				var datea = v.ra1 || v.ra2 || v.ra21;
				if (datea != '') {
					datea = datea.split('至');
					datea[0] = datea[0].replace(/年|月/g, '-').replace('日', '');
					datea[1] = datea[1].replace(/年|月/g, '-').replace('日', '');
					this.ruleForm.ra = datea;
				}
				this.ruleForm.rentprice = v.rentprice.replace('/元/平方/天', '')
				this.ruleForm.rent = v.rent.div(100)
				this.ruleForm.otherrent = v.otherrent.div(100)
				this.ruleForm.property = v.property.div(100)
				this.ruleForm.rentdeposit = v.rentdeposit.div(100)
				this.ruleForm.propertydeposit = v.propertydeposit.div(100)
				this.ruleForm.amountmoney = v.amountmoney
				this.ruleForm.note = v.note

				this.ruleForm.paycount = parseInt(v.paycount);
				this.ruleForm.payed = v.payed
				this.ruleForm.paymoney = v.paymoney
				this.ruleForm.notpay = v.notpay
				this.ruleForm.star = parseInt(v.star)
				this.ruleForm.paytime = v.paytime
			},
			submitpayfrom() {
				this.$refs['payForm'].validate((valid) => {
					if (valid) {
						this.payForm.billid = this.acbill.id
						this.payForm.do = "addpaylog"
						this.$ajax({
							url: 'bill/',
							data: this.payForm
						}).then(res => {

							this.$message({
								message: res.msg,
								type: res.error==0?'success':'warning'
							})
							
							if(res.error==0){
								this.clearpayfrom();
								this.getdata();
								
								this.billloglist = res.data.res;
								this.acbill.paymoney = res.data.paymoney;
								this.acbill.notpay = res.data.notpay
								this.acbill.notpay = res.data.notpay
								this.acbill.offset=res.data.offset

								

							}
						})
					} else {
						return false;
					}
				});
			},
			submitForm_old(formName) {


				this.ruleForm.id = this.acbill.id;
				var info = {
					do: 'update',
					id: this.acbill.id,
					typeid: this.acbill.typeid,
					'info': this.ruleForm
				};

				this.$refs[formName].validate((valid) => {
					if (valid) {
						
						this.$ajax({
							url: 'bill/',
							data: info
						}).then(res => {
							this.$alert(res.msg, '提示', {
								confirmButtonText: '确定'
							});
							if (res.error == 0) {
								
								this.getdata();
							}
						})

					} else {
						return false;
					}
				});
			},
			submitForm(formName) {
				if (this.editbill) {
					this.billForm.do = 'updatebill'
				} else {
					this.billForm.do = 'newbill'
				}
				this.$refs[formName].validate((valid) => {
					if (valid) {
						if(this.billForm.cycle!=''){
							if(this.billForm.money===''){
								this.$alert('请输入月费用', '提示', {
									confirmButtonText: '确定'
								});
								return;
							}
							
						}else{
							if(isNaN(this.billForm.amountmoney)){
								this.$alert('请输入账单金额', '提示', {
									confirmButtonText: '确定'
								});
								return;
							}
							if(!this.billForm.endtime){
								this.$alert('请输入账单日期', '提示', {
									confirmButtonText: '确定'
								});
								return;
							}
						}
						this.billForm.depositmore = []
						for (var i = 0; i < this.depositmorev.length; i++) {
							this.billForm.depositmore.push({
								deposit: this.depositmorev[i],
								date: this.depositmoredate[i]
							})
						}
						
						
						if (this.billForm.dizeng == 1) {
							for (i = 0; i < this.billForm.depositmore.length; i++) {
								if (this.billForm.depositmore[i].deposit == '') {
						
									this.$alert('请输入正确的递增租金和生效日期', {
										confirmButtonText: '确定'
									});
						
									return;
								}
							}
						}
						
						this.$ajax({
							url: 'bill/',
							data: this.billForm
						}).then(res => {
							
							if (res.error == 0) {
								this.isshowbillform=false
								this.page(this.pgno);
								this.$refs['ruleForm'].resetFields();
							}
							this.$alert(res.msg, '提示', {
								confirmButtonText: '确定'
							});
						})

					} else {
						return false;
					}
				});
			},
			negative(){
				
				if (this.editnegative) {
					this.negativeformdata.do = 'updatenegative'
				} else {
					this.negativeformdata.do = 'newnegative'
				}
				if(!this.contractid){
					this.$alert('请先选择要增加负项账单的公司', '提示', {
						confirmButtonText: '确定'
					});
					return;
				}
				this.negativeformdata.contractid=this.contractid;

				
				this.negativeformdata.billids=[this.negativeformdata.id];
				this.$refs['negativeForm'].validate((valid) => {
					if (valid) {
						if(this.negativeformdata.amountmoney<=0){
							this.$alert('账单金额必需大于0', '提示', {
								confirmButtonText: '确定'
							});
							return;
						}
						if(this.loading==false){
							this.loading=true;
							this.$ajax({
								url: 'bill/',
								data: this.negativeformdata
							}).then(res => {
								this.loading=false;
								if (res.error == 0) {
									this.$refs['negativeForm'].resetFields();
									this.isshowsubbillform=false
									this.page(this.pgno);
									
								}
								this.$alert(res.msg, '提示', {
									confirmButtonText: '确定'
								});
							})
						}
					}else{
						return false;
					}
				})
			},
			page(v) {
				this.getdata({
					pgno: v
				});
			},
			getdata(v) {
				if(typeof(v) == 'number'){
					this.pgno=v;
				}
				// if (typeof(v) == 'object'&&v.pgno ) {
				// 	pg=v.pgno;
				// }
				if (this.contractid != '') {
					this.$ajax({
						url: 'contract/',
						data: {
							'do': 'info',
							id: this.contractid,
						},
					}).then(res => {
						this.contract = res.res[0]
						this.customer=res.customer
					})
				}
				var bank='';
				if(this.search.bankname!==''){
					bank=this.banksmap[this.search.bankname]['v'];
				}
				this.$ajax({
					responseType:this.down?'blob':'',
					url: 'bill/',
					data: {
						'do': 'list',
						'search': this.search,
						'bank':bank,
						'pgno': this.pgno,
						'pgsize': this.pgsize,
						'contractid': this.contractid,
						'down':this.down
					},
				}).then(res => {
					if(this.down){
						this.down=0;
						let url=window.URL.createObjectURL(new Blob([res]));
						let link=document.createElement('a')
						link.download='账单.xls'
						link.href=url
						link.click()
						return
					}

					this.paystate = res.paystate;
					this.billstate = res.billstate;
					this.banks = res.banks;
					this.paytype = res.paytype;
					this.res = res.res
					this.allamount=res.allamount?res.allamount:0;
					this.paymoney=res.paymoney?res.paymoney:0;
					this.notpay=res.notpay;
					this.returnm=res.returnm;
					this.allreturnm=res.allreturnm;
					this.pgcount = parseInt(res.count);
					for (var i = 0; i < this.paytype.length; i++) {
						this.paytypemap[this.paytype[i].fid] = this.paytype[i];
					}
					this.officearea=res.officearea
					this.staff=res.staff
					var banks=[];
					for(i=0;i<res.banks.length;i++){
						if(!banks[res.banks[i].comname]){
							banks[res.banks[i].comname]=[i];
						}else{
							banks[res.banks[i].comname].push(i);
						}
						
					}
					var bank=[];
					for(var n in banks){
						bank.push({n:n,v:banks[n]});
					}
					this.banksmap=bank;
					
				})
			},
			zuofei(v,row){
				var type=0;
				var poin=[];
				var po = [];
				console.log(v);
				if(v!=undefined){
					type=1;
					po.push(row.id);
					poin.push(v)
				}else{
					if (this.multipleSelection.length == 0) {
						this.$alert('请至少选中一条账单记录作废', '提示', {
							confirmButtonText: '确定'
						});
						return;
					}
					for (var i = 0; i < this.multipleSelection.length; i++) {
						po.push(this.multipleSelection[i].id);
					}
				
					
				}
				this.$confirm('确定要作废吗?', '提示', {
					confirmButtonText: '确定',
					cancelButtonText: '取消',
					type: 'warning'
				}).then(() => {
					this.$ajax({
						url: 'bill/',
						data: {
							'do': 'void',
							'type':type,
							'po':po,
							'contractid':this.contractid
						},
					}).then(res => {
						if(res.error==0){
							for(var i=0;i<po.length;i++){
								if(type==0){
								this.multipleSelection[i].state=3;
								}else{
									this.res[poin[i]].state=3
								}
							}
							this.page(this.pgno)
							this.contract.state=1;
						}
						this.$message({
								message: res.msg,
								type:'success'
							});
					})
				}).catch(() => {          
				});
				
			},
			allzuofei(){
				this.$confirm('确定要作废吗?', '提示', {
					confirmButtonText: '确定',
					cancelButtonText: '取消',
					type: 'warning'
				}).then(() => {
					this.$ajax({
						url: 'bill/',
						data: {
							'do': 'void',
							'type':2,
							'contractid':this.contractid
						},
					}).then(res => {
						if(res.error==0){
							this.page(this.pgno)
						}
						this.$message({
							message: res.msg,
							type:'success'
						});
					})
				}).catch(() => {          
				});
			}
		},

	}
</script>

<style scoped>
	.el-form-item {
		margin-bottom: 14px;
	}

	.editbill .el-form-item {
		margin-bottom: 10px;
	}

	.editbill .el-row {
		margin-bottom: 0px;
	}

	.el-dialog__body {
		padding-top: 10px;
	}


	.heng {
		display: flex;
		align-items: stretch;
		border-left: 1px solid #eaeaea;
		border-top: 1px solid #eaeaea;
	}

	.heng>view {
		border-right: 1px solid #eaeaea;
		border-bottom: 1px solid #eaeaea;
		padding: 5px;
	}

	.it {
		width: 64px;
		text-align: right;
		overflow-wrap: break-word;
		white-space: pre-wrap;

		border-right: 1px solid #eaeaea;
		border-bottom: 1px solid #eaeaea;
		padding: 5px 6px;
		height: 46px;
	}

	.it0 {
		width: 90px;
		height: 46px;
	}

	.it1 {
		width: 35px;
		text-align: right;
		overflow-wrap: break-word;
		white-space: pre-wrap;
	}

	.it2 {
		width: 35px;
		text-align: right;
		overflow-wrap: break-word;
		white-space: pre-wrap;
	}

	.it3 {
		width: 27px;
		text-align: right;
		overflow-wrap: break-word;
		white-space: pre-wrap;
	}

	.it4 {
		width: 35px;
		text-align: right;
		overflow-wrap: break-word;
		white-space: pre-wrap;
	}

	.it5 {
		width: 50px;
		text-align: right;
		overflow-wrap: break-word;
		white-space: pre-wrap;
	}
</style>
